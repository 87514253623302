<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="HEQ_TYPE_CD"
            label="지게차/운반구 유형"
            itemText="codeName"
            itemValue="code"
            type="search"
            name="facilityTypeCd"
            v-model="searchParam.facilityTypeCd"
          >
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            :editable="editable"
            :plantCd="searchParam.plantCd"
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-vendor
            :editable="editable"
            :plantCd="searchParam.plantCd"
            :processCd="searchParam.processCd"
            label="업체"
            name="vendorCd"
            v-model="searchParam.vendorCd"
          >
          </c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="안전검사기간"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="지게차/운반구  목록"
      :columns="grid.columns"
      :data="grid.data"
      :checkClickFlag="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      
      <template slot="table-button">
        <q-btn-group v-if="isPlant" outline>
          <!-- <c-btn label="QR코드 출력" :editable="editable" icon="save_alt" @btnClicked="btnQrCode" /> -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['validFlagName']">
          <q-chip
            :color="setTagColor(props.row.validFlagName === '유효기간 초과' ? 'Y' : 
            props.row.validFlagName === '입력중' ? 'NE' : 'orange')"
            :class="props.row.validFlagName ==='유효기간 초과' ? 'blinking' : null"
            outline square
            text-color="white">
            <q-avatar v-if="props.row.validFlagName==='유효기간 초과'" icon="alarm" color="red" text-color="white" />
            <q-avatar v-if="props.row.validFlagName==='입력중'" icon="create" color="grey-5" text-color="white" />
            {{setTagName(props.row.validFlagName === '유효기간 초과' ? 'Y' : props.row.validFlagName)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "fork-lift-manage",
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width:150px',
            label: '공사현장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'facilityTypeName',
            field: 'facilityTypeName',
            style: 'width:150px',
            label: '지게차/운반구 유형',
            align: 'center',
            sortable: true,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '지게차/운반구명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'deviceNo',
            field: 'deviceNo',
            label: '기기번호',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'managerNo',
            field: 'managerNo',
            label: '관리번호',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            style: 'width:150px',
            label: 'LBLPROCESS',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'carryinDate',
            field: 'carryinDate',
            label: '반입일',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'useDate',
            field: 'useDate',
            label: '사용기간',
            style: 'width:180px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'safetyDate',
            field: 'safetyDate',
            style: 'width:100px',
            label: '안전검사일',
            align: 'center',
            sortable: true,
          },
          {
            name: 'validFlagName',
            field: 'validFlagName',
            style: 'width:130px',
            label: '안전검사잔여일수',
            type: 'custom',
            align: 'center',
            sortable: true,
          },
          {
            name: 'inspectionDate',
            field: 'inspectionDate',
            style: 'width:100px',
            label: '최근점검일',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        facilityTypeCd: null,
        vendorCd: '',
        processCd: '',
      },
      colorItems: [
        { colorCode: 'Y', colorName: '유효기간 초과', colorClass: 'red' },
        { colorCode: 'NE', colorName: '입력중', colorClass: 'grey-5' },
      ],
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.heq.forkLift.list.url;
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "지게차/운반구 상세"; // 지게차/운반구  상세
      this.popupOptions.param = {
        heqForkLiftId: row ? row.heqForkLiftId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./forkLiftManageDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    // btnQrCode() {
    //   let selectData = this.$refs['table'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // '안내',
    //       message: '지게차/운반구 목록 중 선택된 항목이 없습니다.', // '지게차/운반구 목록 중 선택된 항목이 없습니다.',
    //       type: 'warning',
    //     });
    //     return;
    //   }
    //   if (selectData.length > 10) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // '안내',
    //       message: 'QR코드 일괄출력은 한번에 10개까지 가능합니다.', // 'QR코드 일괄출력은 한번에 10개까지 가능합니다.',
    //       type: 'warning',
    //     });
    //     return;
    //   }
    //   this.popupOptions.title = "지게차/운반구 QR코드"; 
    //   this.popupOptions.param = {
    //     selectData: selectData,
    //   };
    //   this.popupOptions.target = () =>
    //     import(`${"./hazardEquipmentQr.vue"}`);
    //   this.popupOptions.visible = true;
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.width = '800px';
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { colorCode : data })
        return color ? color.colorClass : 'blue';
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { colorCode: data })
        return color ? color.colorName : data;
      }
    },
  },
};
</script>
<style>
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>